import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import Img from 'gatsby-image'

import { media } from '../../utils/media'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import { CallButton, QuoteButton } from '../../components/Button'

import {
  RecentWorkSection,
  HowItWorksSection
}
from '../../components/Section'

// svgs
import SmallCircle from '../../assets/shapes/small-circle.svg'
import SmallMultiply from '../../assets/shapes/small-multiply.svg'
import SmallSquare from '../../assets/shapes/small-square.svg'
import SmallTriangle from '../../assets/shapes/small-triangle.svg'

import BigTriangle from '../../assets/shapes/big-triangle.svg'
import TwoBigCircles from '../../assets/shapes/two-big-circles.svg'
import TwoBigSquares from '../../assets/shapes/two-big-squares.svg'
import BigMultiply from '../../assets/shapes/big-multiply.svg'


const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const rotateReverse = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`

const HeroContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 2rem;
  overflow: hidden;
  position: relative;
`
const HeroContent = ({ hero, typeform }) => {
  const HeroContentRoot = styled.div`
    max-width: 550px;
    margin: auto;

    ${media.desktop`
      text-align: center;
    `}
  `

  const HeroTitle = styled.h1`
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 1rem;

    ${media.desktop`
      font-size: 3rem;
    `}
  `

  const HeroSubTitle = styled.div`
    line-height: 36px;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  `

  const ButtonGroup = styled.div`
    > * {
      margin-bottom: 0.5rem;
    }

    ${media.desktop`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    `}
  `

  return (
    <HeroContentRoot>
      <HeroTitle>{hero.title}</HeroTitle>
      <HeroSubTitle>
        <p>
          Making your designer toy idea into production is not easy. You either
          need a large amount of minimum orders or enormous capital to get
          started. We're here to change that. From custom vinyl toys, plastic or
          even resin toys, we can make it.
        </p>
      </HeroSubTitle>
      <ButtonGroup>
        <QuoteButton url={typeform} />
        <CallButton dark full />
      </ButtonGroup>
    </HeroContentRoot>
  )
}

const RightShapes = ({ images }) => {
  return (
    <div css={css`
      z-index: -1;
      ${media.desktop`
        position: absolute;
      `}
    `}>
      <Img
        fixed={images[0].thumbnail}
        css={css`
        position: absolute !important;
        width: 128px;
        height: auto;
        top: 100px;
        left: 300px;
        transform: rotate(25deg);
      `} />
      <SmallTriangle css={css`
        position: absolute;
        top: 250px;
        left: 400px;
        animation: ${rotate} 60s linear infinite;
      `} />
      <SmallCircle css={css`
        position: absolute;
        top: 398px;
        left: 320px;
        animation: ${rotate} 30s linear infinite;
      `} />
      <Img
        fixed={images[5].thumbnail}
        css={css`
          position: absolute !important;
          top: 634px;
          left: 362px;
          transform: rotate(-15deg);
      `}
      />

      <SmallSquare
        css={css`
          position: absolute;
          top: 800px;
          left: 200px;
          animation: ${rotate} 30s linear infinite;
      `}
      />
    </div>
  )
}

const LeftShapes = ({ images }) => {
  return (
    <div css={css`
      z-index: -1;

      ${media.desktop`
        position: absolute;
        left: 300px;
      `}

      ${media.ultrahd`
        left: 550px;
      `}
    `}>
      <SmallMultiply
        css={css`
          position: absolute;
          top: 6rem;
          left: -8rem;
          animation: ${rotateReverse} 30s linear infinite;
      `}
      />
      <SmallSquare
        css={css`
          position: absolute;
          top: 230px;
          left: 20px;
          animation: ${rotateReverse} 30s linear infinite;
        `}
      />
      <Img
        fixed={images[3].thumbnail}
        css={css`
          position: absolute !important;
          top: 341px;
          left: -11px;
          transform: rotate(-25deg);

          ${media.desktop`
            left: -150px;
          `}
      `}
      />
      <SmallCircle
        css={css`
          position: absolute;
          top: 500px;
          left: -5rem;
        `}
      />
      <Img
        fixed={images[2].thumbnail}
        css={css`
          position: absolute !important;
          top: 700px;
          left: 30px;
          transform: rotate(5deg);
        `}
      />
    </div>
  )
}

const DesignerToyProduction = ({ data }) => {
  const { 
    hero,
    name,
    typeform,
    howItWorks,
    gallery,
    slug,
    metaDescription,
    metaTitle,
  } = data.contentfulService

  const postNode = {
    title: metaTitle || `${name} - Service | NYC | PrintAWorld`,
    metaDescription: metaDescription,
  }

  return (
    <Layout>
      <Helmet>
        <title>{postNode.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={`services/${slug}`} customTitle pageSEO />
      <HeroContainer>
        <LeftShapes images={gallery.images} />
        <RightShapes images={gallery.images} />
        <HeroContent hero={hero} typeform={typeform} />
      </HeroContainer>
      <RecentWorkSection
        gallery={gallery}
        css={css`
          background-color: ${props => props.theme.colors.lightGray};
          ${media.desktop`
            padding: 2rem 0;
          `}
        `}
      />
        <div
          css={css`
            position: relative;
            overflow: hidden;
            opacity: 1;

            ${media.desktop`
              overflow: unset;
            `}
          `}
        >
        <BigTriangle
          css={css`
            position: absolute;
            left: 0;
            transform: translate(-50%, -50%) rotate(18deg);
          `}
        />
        <TwoBigCircles
          css={css`
            position: absolute;
            right: 0;
            transform: translate(50%, 50%);
          `}
        />
        <TwoBigSquares
          css={css`
            position: absolute;
            left: 0;
            transform: translate(-50%, 1000px);
          `}
        />
        <BigMultiply
          css={css`
            position: absolute;
            right: 0;
            transform: translate(25%, 1500px) rotate(60deg);
          `}
        />
        </div>
      <HowItWorksSection steps={howItWorks} />
      <section
        id="#call-to-action"
        css={css`
          background-color: ${props => props.theme.colors.base};
        `}
      >
      <div css={css`
        max-width: ${props => props.theme.sizes.maxWidth};
        margin: auto;
        padding: 6rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ${media.desktop`
          padding: 8rem;
        `}
      `}>
        <h2 css={css`
          font-weight: bold;
          font-size: 2rem;
          color: ${props => props.theme.colors.white};
          text-align: center;
          margin-bottom: 2rem;

          ${media.desktop`
            font-size: 3rem;
          `}
        `}>Are you ready to make your first designer toy?</h2>
        <QuoteButton
          css={css`padding: 0 3rem;`}
          url={typeform}
        >Let's talk</QuoteButton>
        </div>
      </section>
    </Layout>
  )
}

export default DesignerToyProduction

export const designerToyProductionQuery = graphql`
         query {
           contentfulService(slug: { eq: "designer-toy-production" }) {
             name
             slug
             typeform
             metaTitle
             metaDescription {
               internal {
                 content
               }
             }
             gallery {
               images {
                 ...RecentWorkImages
               }
             }
             hero {
               ... on Node {
                 ... on ContentfulHeroTextOnly {
                   ...HeroTextOnly
                 }
                 ... on ContentfulHero {
                   ...HeroFragment
                 }
               }
             }
             howItWorks {
               ...HowItWorksCardFragment
             }
           }
         }
       `